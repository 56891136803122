import classNames from "classnames";
import { useTranslation } from "react-i18next";
import style from "../../../styles/main.module.css";
import { Image } from "antd";

export default function Experience() {
  const { t, i18n } = useTranslation("common");

  return (
    <div className={"container mx-auto mt-[50px] lg:mt-[100px] px-4 md:px-0"}>
      <div
        className={classNames(
          "flex justify-between flex-col-reverse lg:flex-row mx-5 gap-10 lg:gap-0"
        )}
      >
        <div
          className={classNames(
            style.video_exp_box,
            "flex w-full lg:mr-10 md:max-h-[20rem]  max-h-[50rem] justify-center rounded-2xl my-auto mx-auto lg:mx-0"
          )}
        >
          <div
            className={classNames(style.play_exp_box, "flex justify-center")}
          >
            <div className={style.play_icon}>
              <Image
                className={style.ds1}
                src="/main/ds1.svg"
                alt="play"
                loading="lazy"
                preview={false}
                fallback={"/static/images/placeholder/provider.jpg"}
              />
              <Image
                src="/static/images/ds2.svg"
                className={classNames(style.ds2)}
                alt="play"
                loading="lazy"
                preview={false}
                fallback={"/static/images/placeholder/provider.jpg"}
              />
            </div>
          </div>
          <Image
            preview={false}
            src="/static/images/home-categories/video-element.webp"
            alt="moverii.de"
            loading="lazy"
            className={
              "max-h-[20rem] md:h-[285px] h-[11rem] rounded-2xl w-full object-cover flex self-center"
            }
            fallback={"/static/images/placeholder/provider.jpg"}
          />
        </div>

        <div
          className={classNames(
            style.experience_detail,
            "lg:ml-8 px-2 sm:px-0 -mx-4 sm:mx-0"
          )}
        >
          <div className={"flex flex-row my-auto py-[24px] md:-ml-5"}>
            <h3
              className={
                "!font-black catamaran inline-block lg:-ml-2"
              }
            >
              <span className={"inline-block text-[24px] md:text-[28px] !font-black"}>
                {t("frontpage.section_experience_title")}
              </span>
              <span className={"inline-block align-middle -mt-[15px] px-2"}>
                <img
                  src="/static/images/logo.svg"
                  className={"w-[95px]"}
                  alt="moverii.de"
                  loading="lazy"
                />
              </span>
              <span className={"inline-block text-[24px] md:text-[28px] !font-black"}>
                {t("frontpage.section_experience_title2")}
              </span>
            </h3>
          </div>

          {i18n.language == "en" ? (
            <div className={"flex flex-col space-y-3 md:-ml-5"}>
              <div className="flex flex-col justify-start gap-1">
                <h6 className={"font-bold !text-[18px]"}>Local</h6>
                <p>
                  All journeys are organized by local hosts who are passionate
                  about what they do and cater to your individual preferences.
                </p>
              </div>
              <div className="flex flex-col justify-start gap-1">
                <h6 className={"font-bold text-[18px]"}>Holistic</h6>
                <p>
                  Your travel package always consists of accommodation and
                  sports or acitivities. At times, meals and other activities
                  are also included.
                </p>
              </div>
              <div className="flex flex-col justify-start gap-1">
                <h6 className={"font-bold text-[18px]"}>Diverse</h6>
                <p>
                  With our selection of over 500 journeys, you will find just
                  the right vacation! Be it camping or luxury, surfing or
                  hiking, alone or with the family.
                </p>
              </div>
              <div className="flex flex-col justify-start gap-1">
                <h6 className={"font-bold text-[18px]"}>Together</h6>
                <p>
                  Sport connects! Traveling with moverii, you easily with
                  like-minded people from across the globe in no time. Maybe you
                  even make friends for life.
                </p>
              </div>
              <div className="flex flex-col justify-start gap-1">
                <h6 className={"font-bold text-[18px]"}>Straightforward</h6>
                <p>
                  The search, the communication and the payment - you have it
                  all in one place, securely and conveniently from anywhere in
                  the world.
                </p>
              </div>
            </div>
          ) : (
            <div className={"flex flex-col space-y-3 mb-auto md:-ml-5"}>
              <div className="flex flex-row items-center">
                <img
                  className="mr-3 w-[24px] h-[24px] sm:w-[30px] sm:h-[30px]"
                  src={"/icon/offer/checkMark.svg"}
                  alt="checkMark"
                  loading="lazy"
                />
                <p className="text-[18px]">
                  ... deinen Urlaub aktiv erleben willst
                </p>
              </div>
              <div className="flex flex-row items-center">
                <img
                  className="mr-3 w-[24px] h-[24px] sm:w-[30px] sm:h-[30px]"
                  src={"/icon/offer/checkMark.svg"}
                  alt="checkMark"
                  loading="lazy"
                />
                <p className="text-[18px]">
                  ... die Natur entdecken und unkompliziert reisen willst
                </p>
              </div>
              <div className="flex flex-row items-center">
                <img
                  className="mr-3 w-[24px] h-[24px] sm:w-[30px] sm:h-[30px]"
                  src={"/icon/offer/checkMark.svg"}
                  alt="checkMark"
                  loading="lazy"
                />
                <p className="text-[18px]">
                  ... offen für neue Menschen und Kulturen bist
                </p>
              </div>
              <div className="flex flex-row items-center">
                <img
                  className="mr-3 w-[24px] h-[24px] sm:w-[30px] sm:h-[30px]"
                  src={"/icon/offer/checkMark.svg"}
                  alt="checkMark"
                  loading="lazy"
                />
                <p className="text-[18px]">
                  ... dir ein ganzheitliches Reisepaket aus Unterkunft und
                  Sportprogramm wünschst
                </p>
              </div>
              <div className="flex flex-row items-center">
                <img
                  className="mr-3 w-[24px] h-[24px] sm:w-[30px] sm:h-[30px]"
                  src={"/icon/offer/checkMark.svg"}
                  alt="checkMark"
                  loading="lazy"
                />
                <p className="text-[18px]">
                  ... dir keine Sorgen um die Qualität oder Organisation machen
                  willst
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
