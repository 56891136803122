import { SearchIcon } from "@components/search/ComponentIcon";
import { Image, Skeleton } from "antd";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Traveler from "./blog";
import Experience from "./exprience";
import SectionCommunity from "./main_section_comminity";

const HomePlaceholder = () => {
  const topRef = useRef(null);
  const { t } = useTranslation("common");

  useEffect(() => {
    topRef.current?.scrollIntoView(false, { behavior: "smooth" });
  }, []);

  return (
    <div>
      <section className="!h-[520px] md:!h-[550px] lg:!h-[530px] relative">
        <Image
          src="/main/main-moverii-banner.webp"
          alt="moverii.de"
          loading="lazy"
          preview={false}
          width={"100%"}
          className="!w-full !h-[520px] md:!h-[550px] lg:!h-[530px] object-cover object-center"
        />
        <div className="!absolute top-[18%] md:top-[15%] !mx-auto w-full flex flex-col items-center gap-y-3">
          <h1 className="text-white text-center md:text-[52px] mt-10 md:mt-3 catamaran leading-tight text-[48px] sm:text-[52px] font-black drop-shadow-lg lg:pt-5">
            {t("frontpage.banner_text_title")}
          </h1>
          <h3 className="text-white text-center text-2xl md:text-[26px] lg:text-[30px] font-semibold drop-shadow-lg !mb-10">
            {t("frontpage.subtitle_banner")}
          </h3>
          <div className="!h-[74px] !w-full md:!w-[600px] lg:!w-[917px] relative bg-white !rounded-full !mx-4 md:!mx-0">
            <Skeleton.Button
              active
              className="!w-full !h-[74px] !rounded-full !bg-white"
            />
            <button className="rounded-full md:mb-0 mb-10 w-[56px] h-[54px] md:w-14 font-medium  bg-[#FF9F1C] p-4 -mr-1 absolute right-5 top-[10px]">
              <SearchIcon />
            </button>
          </div>
        </div>
      </section>

      <SectionCommunity />

      <section className="container mt-[50px] md:mt-[100px] px-4 md:px-0">
        <h2 className="text-center mb-[30px] font-bold text-[28px] catamaran">
          {t("frontpage.cat_list")}
        </h2>
        <div className="w-full flex items-center overflow-hidden gap-5">
          {[1, 2, 3, 4, 5, 6, 7].map((i) => (
            <Skeleton.Button
              key={"skeleton" + i}
              active
              className="!h-[290px] !w-[200px] md:!w-[280px] !rounded-3xl"
            />
          ))}
        </div>
      </section>

      <section className="w-full mt-[100px] py-[50px] md:py-[100px] min-h-[450px]">
        <h2 className="font-bold text-[28px] catamaran flex justify-center pb-[24px] md:pb-[30px]">
          {t("frontpage.Popular destinations") || t("main.Beliebte Reiseziele")}
        </h2>
        <div className="w-full flex items-center overflow-hidden gap-5 container px-4 md:px-0">
          {[1, 2, 3, 4, 5, 6, 7].map((i) => (
            <Skeleton.Button
              key={"skeleton" + i}
              active
              className="!h-[373px] !w-[200px] md:!w-[228px] !rounded-3xl !bg-white"
            />
          ))}
        </div>
      </section>

      <section>
        <Experience />
      </section>

      <section className="mt-[50px] md:mt-[100px] container w-full flex flex-col items-center sm:pb-10 px-4 md:px-0 min-h-[607px]">
        <h2 className={"font-bold text-[28px] catamaran"}>
          {t("frontpage.title_offers")}
        </h2>
        <div className="w-full flex items-center overflow-hidden gap-5 px-4 md:px-0 mt-5">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
            <Skeleton.Button
              key={"skeleton" + i}
              active
              className="!h-[500px] !w-[250px] md:!w-[260px] !rounded-3xl"
            />
          ))}
        </div>
      </section>

      <section className="mt-[50px] md:mt-[100px] min-h-[382px] container !z-30">
        <h2
          className={
            "font-bold text-center catamaran text-[28px] pb-[24px] md:pb-[30px]"
          }
        >
          {t("frontpage.testimonial")}
        </h2>
        <div className="w-full flex items-center overflow-hidden gap-5 px-4 md:px-0 mt-5">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
            <Skeleton.Button
              key={"skeleton" + i}
              active
              className="!h-[277px] !w-[250px] md:!w-[386px] !rounded-3xl"
            />
          ))}
        </div>
      </section>

      <section className="bg-[#e1f9fc] pt-[150px] -mt-[150px] !z-10">
        <Traveler />
      </section>
    </div>
  );
};

export default HomePlaceholder;
