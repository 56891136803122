import dynamic from "next/dynamic";
import { createElement } from "react";
const dynamicImport = (data, componentList) => {
  const modul = [];
  data.map((data) => {
    if (componentList.hasOwnProperty(data.component_name)) {
      const path = componentList[data.component_name];

      try {
        modul.push(
          createElement(
            dynamic(() => path, {
              loading: (e) => {
                return <p>Loading...</p>;
              },
              ssr: true,
              error: (e) => {},
            }),
            {
              data: data,
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    }
  });
  return modul;
};

export default dynamicImport;
