import Sectioncomminity from "@frontpage/section_comminity";

export default function HomeSectioncomminity(props) {
  return (
    <section
      className={`${
        props.IsDynamic
          ? "pt-2"
          : "pb-[0px] pt-[50px] md:pb-[50px] md:pt-[100px] px-4 md:px-0 z-0"
      }`}
    >
      <Sectioncomminity isHome={props?.data?.isHome ? true : false} />
    </section>
  );
}
