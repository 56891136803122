import { API_ENDPOINTS } from "@libs/request/endpoints_helper";
import { CoreApi } from "../core/CoreApi";

class MainRequest extends CoreApi {
  frontpage(input = {}) {
    return this.http
      .get(API_ENDPOINTS.FRONTPAGE, input)
      .then((res) => res.data);
  }
}
export const MainService = new MainRequest("auth");
