import dynamicImport from "@libs/frontpage_builder/dynamicImport";
import { makeAutoObservable } from "mobx";
import { MainService } from "../../queries/main/main.service";

export default class mainController {
  componentList = {
    banner: import("@frontpage/banner_top"),
    sectionEco: import("@frontpage/main_section_comminity"),
    category: import("@frontpage/category"),
    distination: import("@frontpage/destination"),
    experiance: import("@frontpage/exprience"),
    offers: import("@frontpage/offersList"),
    blog: import("@frontpage/blog"),
    testemunia: import("@frontpage/traveler/testimonial"),
    customlink: import("@frontpage/Inspiration"),
    partner: import("@frontpage/partners"),
  };
  data = [];
  modul = [];
  rawData = "";

  constructor() {
    makeAutoObservable(this);
  }

  setData = (data) => {
    const arr = [];
    data.data?.map((item, i) => {
      arr[i] = {
        title: item.title,
        component_name: item.component_name,
        data: item.data,
      };
    });
    this.data = arr;
    this.modul = dynamicImport(this.data, this.componentList);
  };
  getAll = async (callback) => {
    MainService.frontpage()
      .then((data) => {
        this.rawData = data;
        this.setData(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
}
