import classNames from "classnames";
import Link from "next/link";

export default function Comminity(props) {
  return (
    <section
      className={
        "flex w-full lg:basis-1/3 flex-col sm:flex-row lg:flex-col items-center sm:items-start lg:items-center justify-start lg:px-2 gap-y-4 sm:gap-7 lg:gap-0"
      }
    >
      <div
        className={classNames(
          "w-[70px] lg:w-16  h-16 rounded-full flex items-center justify-center",
          props.className
        )}
      >
        <div>
          <img
            src={props.src}
            alt="moverii icon"
            loading="lazy"
            className="w-full"
          />
        </div>
      </div>
      <div className="flex flex-col items-center sm:items-start lg:items-center gap-3 w-full lg:w-auto">
        <h5 className={"font-bold text-[22px] "}>{props.title}</h5>
        <p
          className={
            "text-center sm:text-left lg:text-center lg:w-auto px-2 sm:px-0 lg:px-2 text-[16px]  h-18"
          }
        >
          {props.description}
        </p>
        {!props?.isHome && (
          <div>
            <Link href={props.link}>
              <a className={props.link}>
                <div className={"flex flex-row  space-x-4 items-center"}>
                  <div className="main-color text-[16px]">{props.textLink}</div>
                  <div>
                    <img
                      src="/main/right_arrow_black.svg"
                      alt="right arrow"
                      loading="lazy"
                    />
                  </div>
                </div>
              </a>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
}
