import Newsletter from "@components/Newsletter/Newsletter";
import { useTranslation } from "next-i18next";

export default function Traveler(props) {
  const { t } = useTranslation("common");
  return (
    <div>
      <div className="w-full bg-[#e1f9fc] pt-[50px] lg:pt-[70px] pb-12">
        <Newsletter
          title={t("contact.newsletter_title")}
          description={t("contact.newsletter_description")}
          inputPlaceholder={t("contact.newsletter_placeholder")}
          buttonTitle={t("contact.newsletter_button")}
          isOnHomePage={true}
        />
      </div>
    </div>
  );
}
